import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { map, take } from 'rxjs/operators';
import { Observer, Observable, EMPTY } from 'rxjs';
import { removeEmptyObjectValues } from '../../shared/helpers/other';
import { AttachmentItemsForList } from '../../shared/models/employee-attachment.models';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  response: string;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    @Inject('API_URL') private apiUrl: string,
  ) {}

  // admin endpoints
  getEmployeeAttachments(uid: string, filters?: any): Observable<{ attachmentsList: AttachmentItemsForList[]; totalAtt: number }> {
    let params: any = { perPage: 100 };
    if (filters) {
      params = {
        ...params,
        ...filters,
      };
    }

    Object.keys(params || {}).forEach((key: string) => {
      if (Array.isArray(params[key])) {
        params[key + '[]'] = [...params[key]];
        delete params[key];
      }
    });
    return this.http
      .get(`${this.apiUrl}/users/${uid}/attachment`, {
        params: removeEmptyObjectValues(params),
        observe: 'response',
      })
      .pipe(
        map((success: HttpResponse<any>) => ({
          attachmentsList: success.body,
          totalAtt: +success.headers.get('X-Items-Count'),
        })),
      );
  }

  getEmployeeAttachmentLink(uid: string, attachmentIds: number | string | string[]): Observable<any> {
    if (Array.isArray(attachmentIds)) {
      const params: { 'attachmentIds[]': string[] } | string = { 'attachmentIds[]': attachmentIds.map((item: any) => item.id || item) };
      return this.http.get(`${this.apiUrl}/v2/employee/${uid}/attachments/multiple-download`, {
        params: removeEmptyObjectValues(params),
      });
    } else {
      return this.http.get(`${this.apiUrl}/users/${uid}/attachment/${attachmentIds}`);
    }
  }

  updateEmployeeAttachment(uid: string, attachmentId: string, body: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/users/${uid}/attachment/${attachmentId}`, body);
  }

  deleteEmployeeAttachment(uid: string, attachmentId: number | string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/users/${uid}/attachment/${attachmentId}`);
  }

  addAttachmentComment(uid: string, attachmentId: string, comment: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/${uid}/attachment/${attachmentId}/comment`, { comment });
  }

  // employee endpoints
  getAttachments(filters = {}): Observable<any> {
    let params;
    if (filters) {
      params = {
        ...filters,
      };
    }
    Object.keys(params || {}).forEach((key: string) => {
      if (Array.isArray(params[key])) {
        params[key + '[]'] = [...params[key]];
        delete params[key];
      }
    });
    params = {
      ...params,
      search: params.search || params.query || null,
    };
    delete params.query;
    return new Observable((observer: Observer<any>) => {
      this.http
        .get(`${this.apiUrl}/employee/attachment`, {
          params: removeEmptyObjectValues(params),
          observe: 'response',
        })
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<any[]>) => {
            observer.next({
              attachmentsList: success.body,
              totalAtt: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          },
        });
    });
  }

  postAttachment(body: any, uid?: string): Observable<any> {
    const url: string = uid ? `${this.apiUrl}/users/${uid}/attachment` : `${this.apiUrl}/employee/attachment`;
    const formData: FormData = new FormData();
    Object.keys(body || {}).forEach((key: string) => formData.append(key, body[key]));
    return this.http.post(url, formData);
  }

  getAttachmentLink(id: number | string | string[]): Observable<any> {
    if (Array.isArray(id)) {
      const params = { 'ids[]': id };
      return this.http.get(`${this.apiUrl}/employee/attachment/multiple`, {
        params: removeEmptyObjectValues(params),
      });
    } else {
      return this.http.get(`${this.apiUrl}/employee/attachment/${id}`);
    }
  }

  updateAttachment(id: string, body): Observable<any> {
    return this.http.put(`${this.apiUrl}/employee/attachment/${id}`, body);
  }

  setMandatoryAttachmentListByEmployee(uid: string, idAttachment: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/v2/users/${uid}/attachment/${idAttachment}/mandatory`, {});
  }

  deleteAttachment(id: string | number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/employee/attachment/${id}`);
  }

  syncAttachment(uid: string, attachmentId: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/${uid}/attachment/${attachmentId}/hhaexchange`, {});
  }

  // incident attachments
  getIncidentAttachments(id: string, filters?: any): Observable<any> {
    if (id) {
      let params: any = { id };
      if (filters) {
        params = {
          ...params,
          ...filters,
          search: (filters.search || '').trim(),
        };
      }
      Object.keys(params || {}).forEach((key: string) => {
        if (Array.isArray(params[key]) && key !== 'xchangeSync') {
          params[key + '[]'] = [...params[key]];
          delete params[key];
        }
      });
      if (params.date) {
        params = {
          ...params,
          dataStart: this.datePipe.transform(params.date.from, 'yyyy-MM-dd') || '',
          dataEnd: this.datePipe.transform(params.date.to, 'yyyy-MM-dd') || '',
        };
        if (!params.dataStart) {
          delete params.dataStart;
        }
        if (!params.dataEnd) {
          delete params.dataEnd;
        }
        delete params.date;
      }
      delete params.query;
      const endpoint: string = `${this.apiUrl}/incidents/incidents/${id}/attachments`;
      return new Observable((observer: Observer<any>) => {
        this.http.get(endpoint, { params: removeEmptyObjectValues(params), observe: 'response' }).subscribe({
          next: (success: HttpResponse<any[]>) => {
            observer.next({
              data: success.body,
              total: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          },
        });
      });
    } else {
      return EMPTY;
    }
  }

  postIncidentAttachment(body: any, id?: string): Observable<any> {
    const url: string = `${this.apiUrl}/incidents/incidents/${id}/attachments`;
    const formData: FormData = new FormData();
    Object.keys(body || {}).forEach((key: string) => formData.append(key, body[key]));
    return this.http.post(url, formData);
  }

  deleteIncidentAttachment(id: string | number, attachmentId: string | number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/incidents/incidents/${id}/attachments/${attachmentId}`);
  }

  getIncidentAttachmentLink(id: string, attachmentIds: string | string[]): Observable<any> {
    if (Array.isArray(attachmentIds)) {
      const params = { 'ids[]': attachmentIds };
      return this.http.get(`${this.apiUrl}/incidents/incidents/${id}/attachments/multiple`, {
        params: removeEmptyObjectValues(params),
      });
    } else {
      return this.http.get(`${this.apiUrl}/incidents/incidents/${id}/attachments/${attachmentIds}`);
    }
  }

  sendPatientIncidentToHha(id: number | string, attachmentId: string | number): Observable<any> {
    return this.http.post(`${this.apiUrl}/incidents/incidents/${id}/attachments/${attachmentId}/hha-sync`, {});
  }

  editIncidentAttachmentComment(id: string, attachmentId: string, comment: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/incidents/incidents/${id}/attachments/${attachmentId}/comment`, { comment });
  }

  updateIncidentAttachmentTitle(id: string, attachmentId: string, title: string): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/incidents/incidents/${id}/attachments/${attachmentId}`, { title });
  }
}
