import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DepartmentsState } from '../../dashboard/store/departments/departments.state';
import { Observable } from 'rxjs';
import { NgxsModule, Select, Store } from '@ngxs/store';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { excludeChar } from '../../shared/validators/exclude-char.validator';
import { removeEmptyObjectValues } from '../../shared/helpers/other';
import { GenerateEmployee } from '../../dashboard/store/employees/employees.actions';
import { take } from 'rxjs/operators';
import { MaskDirective } from '../../shared/directives/mask.directive';
import { PHONE_MASK } from '../../shared/helpers/phone-mask';

enum SelectIdEnum {
  Email = 'email',
  Phone = 'phone',
}

@Component({
  selector: 'app-choose-department',
  templateUrl: './choose-department.component.html',
  styleUrls: ['./choose-department.component.scss'],
  standalone: true,
  imports: [NgxsModule, ReactiveFormsModule, CommonModule, MaskDirective],
})
export class ChooseDepartmentComponent implements OnInit {
  @Output() closed: EventEmitter<any> = new EventEmitter();

  @Select(DepartmentsState.list) departments$: Observable<any>;
  employeeForm: FormGroup;
  generated: boolean;
  step = 1;
  phoneMask = PHONE_MASK;
  errors = {
    id: '',
    password: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };
  SelectIdEnum = SelectIdEnum;

  constructor(
    private fb: FormBuilder,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.initForms();
  }

  nextPage(): void {
    this.step += 1;
  }

  prevPage(): void {
    this.step -= 1;
  }

  generate(): void {
    this.store
      .dispatch(new GenerateEmployee(this.getEmployeeFormPayload()))
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.nextPage();
        },
        error: ({ error }): void => {
          if (error.violations) {
            Object.keys(error.violations || {}).forEach((key: string) => {
              this.employeeForm.get(key).setErrors({ 'has-error': true });
              this.employeeForm
                .get(key)
                .valueChanges.pipe(take(1))
                .subscribe(() => {
                  this.resetError(key);
                });
            });
            this.errors = { ...this.errors, ...error.violations };
          }
        },
      });
  }

  setDepartment(id: number): void {
    this.employeeForm.patchValue({ departmentId: id });
  }

  resetFormFields(value: string[]): void {
    value.forEach((item: string): void => {
      this.employeeForm.controls[item].setValue('');
    });
  }

  private getEmployeeFormPayload(): any {
    delete this.employeeForm.value.selectId;
    return removeEmptyObjectValues(this.employeeForm.value);
  }

  private resetError(key): void {
    this.errors[key] = '';
    this.employeeForm.get(key).setErrors(null);
  }

  private initForms(): void {
    this.employeeForm = this.fb.group({
      selectId: 'email',
      departmentId: [null, Validators.required],
      email: [null, Validators.email],
      phone: [null, [excludeChar('_')]],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      password: [null, Validators.required],
    });
  }
}
